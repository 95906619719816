<template>
  <div class="login-failure">
    <h2 class="title">Login failed</h2>
    <p class="text">The email address or password is incorrect.
      Please try again.</p>

    <button @click="$emit('retry')" type="button" class="button-main button-try">Try again</button>
  </div>
</template>

<script>
export default {
  name: "LoginFailed",
  emits: ['retry']
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.login-failure {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  .title {
    font-size: rem(24);
    font-weight: bold;
    line-height: rem(29);
    margin-bottom: rem(32);
  }

  .text {
    @extend .mont-regular;
    color: var(--gray-light-03);
    font-size: rem(20);
    line-height: rem(30);
    margin-bottom: rem(48);
  }

  .button-try {
    height: rem(52);
    width: rem(150);
  }
}
</style>
