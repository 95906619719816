<template>
  <div class="logging-indicator">
    <div class="loader"></div>
    <p class="text">Logging in...</p>
  </div>
</template>

<script>
export default {
  name: "LoggingIn"
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.logging-indicator {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .text {
    font-size: rem(17);
    line-height: rem(20);
    margin-top: rem(28);
  }
}

/** Spinner **/
.loader, .loader:after {
  border-radius: 50%;
  width: rem(64);
  height: rem(64);
}

.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: rem(8) solid var(--gray-main);
  border-right: rem(8) solid var(--gray-main);
  border-bottom: rem(8) solid var(--gray-main);
  border-left: rem(8) solid var(--red-main);
  transform: translateZ(0);
  animation: load8 1.3s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
