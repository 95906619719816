<template>
  <div class="auth-page-wrapper">
    <!-- Logo -->
    <img v-if="!loginFailure" src="../../assets/icons/x2/img_billy_logo@2x.png" class="auth-logo" alt="Logo"/>

    <div v-show="!loginFailure && !isLoading" class="login-form">
      <FormWrapper :send-form-data="validateForm" :mode="3">
        <template #form-content>

          <!-- E-mail field -->
          <BasicInputField field-title="Email address"
                           field-name="email"
                           rules="required"
                           type="email"
                           placeholder="Enter email address"
                           cy-selector="email-input"
                           :icon="emailIcons"
                           class="login-input"/>

          <!-- Password field  -->
          <BasicInputField field-title="Password"
                           field-name="password"
                           rules="required"
                           type="password"
                           placeholder="Enter password"
                           cy-selector="password-input"
                           :icon="passwordIcons"
                           class="login-input"/>
        </template>

        <!-- Submit button -->
        <template #button-submit="{isInvalid}">
          <button :disabled="isInvalid"
                  :class="{'button-disabled': isInvalid}"
                  type="submit"
                  class="button-main auth-button button-login">
            Log in
          </button>
        </template>

      </FormWrapper>

      <router-link :to="{name: ROUTE_NAMES_AUTH.FORGOT_PASSWORD}" class="forgot-password">Forgot password?</router-link>
    </div>

    <!-- UI for login failure. -->
    <LoginFailed v-if="loginFailure" @retry="retryLogin"/>

    <!-- UI for logging in indication. -->
    <LoggingIn v-if="isLoading"/>
  </div>
</template>

<script>
import {ref, computed} from "@vue/composition-api"
import FormWrapper from '@/components/yo-form/FormWrapper'
import BasicInputField from '@/components/yo-form/BasicInputField'
import LoginFailed from "../../components/partials/auth/LoginFailed";
import LoggingIn from "../../components/partials/auth/LoggingIn";
import InputIcons from "@/models/input/InputIcons";
import {SEND_LOGIN} from "../../store/auth/actions";
import {ROUTE_NAMES_PROJECT_OVERVIEW} from "../../router/modules/projectOverview";
import {ROUTE_NAMES_AUTH} from "@/router/modules/auth";

export default {
  name: "Login",
  components: {
    FormWrapper,
    BasicInputField,
    LoginFailed,
    LoggingIn,
  },
  setup(_, {root}) {
    const store = root.$store;
    const router = root.$router;

    /** Input icons **/
    const isLoading = computed(() => store.getters.isPageLoading);

    /** Login failure / Error **/
    const loginFailure = ref(false);

    function retryLogin() {
      loginFailure.value = false;
    }

    /** Form validation **/
    function validateForm(formData) {
      sendLogin(formData).then(response => {
        if (response) {
          router.push({name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW});
        } else {
          loginFailure.value = true;
        }
      });
    }

    /** Login **/
    function sendLogin(formData) {
      return store.dispatch(SEND_LOGIN, formData);
    }

    /** Input icons **/
    const emailIcons = new InputIcons(require('../../assets/icons/svg/ic_user_active.svg'), require('../../assets/icons/svg/ic_user_inactive.svg'), require('../../assets/icons/svg/ic_user_error.svg'));
    const passwordIcons = new InputIcons(require('../../assets/icons/svg/ic_password_active.svg'), require('../../assets/icons/svg/ic_password_inactive.svg'), require('../../assets/icons/svg/ic_password_error.svg'));

    /** Errors **/
    const authError = computed(() => store.getters.getAuthError);


    return {
      ROUTE_NAMES_AUTH,

      /** Loading **/
      isLoading,

      /** Login failure **/
      loginFailure,
      retryLogin,

      /** Form validation **/
      validateForm,

      /** Input icons **/
      emailIcons,
      passwordIcons,

      /** Errors **/
      authError
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.login-input {
  &:not(:last-of-type) {
    margin-bottom: rem(20);
  }
}

.button-login {
  margin-top: rem(40);
}

.forgot-password {
  color: #E65540;
  font-family: var(--open-sans);
  margin-top: rem(63);
  text-decoration: underline;
}
</style>
